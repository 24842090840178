<template>
  <!-- <div class="mx-auto h-full py-2 w-1600 px-2"> -->
  <div
    class="element flex-grow h-full max-h-1080 border-2 border-silver bg-silver shadow-sm overflow-scroll overflow-x-hidden"
  >
    <slot></slot>
  </div>
  <!-- </div> -->
</template>

<style>
.element {
  /* background-image: url(../../../assets/backgroundLeaf.svg); */
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center bottom -500px;
}
</style>
